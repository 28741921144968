<template>
  <div class="container filter__donatur-wrapper d-flex align-items-center justify-content-between">
    <div class="title__donature">
      <Skeleton width="69px" height="21" />
    </div>
    <div class="filter_donatur">
      <Skeleton width="107px" height="37" />
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'
export default {
  components: { Skeleton }
}
</script>
