<template>
  <Skeleton v-if="loading" width="100" height="72" />
  <navbar-donatur v-else>
    <div class="w-100 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <a class="navbar-brand" @click="$router.back(-1)">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
              fill="#F2F2F2"
            />
          </svg>
        </a>
        <h6 class="title-navbar">
          Donatur
        </h6>
      </div>
      <div class="due__date-wrapper">
        <img src="../assets/svg/icon-date.svg" alt="icon-date" class="icon__date" />
        <b>{{wakafValue}}</b> &nbsp; {{wakafType}} lagi
      </div>
    </div>
  </navbar-donatur>

  <div v-if="loading" class="campaign__donatur-wrapper">
    <Skeleton width="70" height="20" />
    <Skeleton width="30" height="20" style="margin-top:0.5rem;" />
  </div>
  <div v-else class="campaign__donatur-wrapper">
    <h6 class="title__campaign-donatur">
      {{ wakafName }}
    </h6>
  </div>

  <LoaderFilterDonatur v-if="loading" />
  <FilterDonatur v-else :totalDonatur="totalDonatur" />
  <ListDonatur v-if="wakafId !== null" :wakafId="wakafId" />

  <div v-if="!loading" class="donation__wrapper">
    <div class="group__donation-wrapper">
      <router-link
        :to="{ path: `/transaksi/${wakafUrl}`, query: { transactionStep: 'step-1' } }"
        class="btn btn-warning btn-method"
      >
        Donasi Sekarang
      </router-link>
    </div>
  </div>
</template>

<script>
import NavbarDonatur from '../components/TransactionComponent/NavbarTransaction.vue'
import FilterDonatur from '../components/FilterDonatur/FilterDonatur.vue'
import ListDonatur from '../components/ListDonatur/ListDonatur.vue'

import Skeleton from '../components/Skeleton/Skeleton.vue'
import LoaderFilterDonatur from '../components/Loader/LoaderFilterDonature.vue'

export default {
  name: 'Donatur',
  components: {
    NavbarDonatur,
    FilterDonatur,
    ListDonatur,
    Skeleton,
    LoaderFilterDonatur
  },
  data() {
    return {
      wakafValue: null,
      wakafType: null,
      wakafName: null,
      wakafId: null,
      wakafUrl: null,
      totalDonatur: null,
      donors: [],
      loading: true
    }
  },
  mounted() {
    this.getDetailCampaign()
  },
  methods: {
    getDetailCampaign() {
      const slugUrl = this.$route.params.slug

      this.$http.get(`${process.env.VUE_APP_API_URL}campaigns/${slugUrl}`).then(response => {
        this.totalDonatur = response.data.summary.total_donatur
        this.wakafId = response.data.wakaf_id
        this.wakafName = response.data.wakaf_name
        const valueTarget = this.$moment(response.data.target_date)
          .startOf('day')
          .fromNow()
        const splitString = valueTarget.split(' ')
        this.wakafType = splitString[2]
        this.wakafValue = splitString[1]
        this.wakafUrl = response.data.wakaf_url

        this.loading = false
      })
    }
  }
}
</script>
