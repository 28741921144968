<template>
  <div class="container container__donor-wrapper">
    <div v-for="n in count" :key="n" class="card list__donor-wrapper">
      <div class="card-body card__body-donor">
        <div class="wrapper__information-donor">
          <div>
            <Skeleton width="48px" height="48" style="border-radius:50%;" />
          </div>
          <div style="margin-left:.5rem;">
            <Skeleton width="80px" height="18" />
            <Skeleton width="40px" height="18" style="margin-top:.5rem;" />
          </div>
        </div>
        <div class="time__donor-wrapper">
          <Skeleton width="85px" height="13" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'

export default {
  components: { Skeleton },
  props:{
    count: { type: Number, default: 0 }
  }
}
</script>
