<template>
  <div class="container filter__donatur-wrapper d-flex align-items-center justify-content-between">
    <div class="title__donatur">
      <h6>{{ totalDonatur }} Donatur</h6>
    </div>
    <div class="filter__donatur">
      <select
id="filter" name="filter"
class="form-select filter__select-donatur"
>
        <option value="terbaru">
          Terbaru
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalDonatur: { type: Number, default: 0 }
  },
}
</script>
