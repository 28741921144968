<template>
  <LoaderListDonatur v-if="loading" :count="5" />
  <div v-else class="conatiner container__donor-wrapper">
    <div
      v-for="(donor, index) in donors"
      :key="index"
      class="card list__donor-wrapper"
      :style="{ height: donor.note !== null ? '100%' : '80px' }"
    >
      <div class="card-body card__body-donor">
        <div class="wrapper__information-donor">
          <div class="image__gallery-donor">
            <div class="image__cover-donor">
              <img v-if="donor.photoUrl !== null" :src="donor.photoUrl" alt="Image Donor" class="image__donor" />
              <div v-else class="profile__image">
                {{ donor.displayName.charAt(0) }}
              </div>
            </div>
          </div>
          <div class="information__donor">
            <h6 class="name__donor" style="text-transform: uppercase;">{{ donor.displayName }}</h6>
            <p v-if="donor.barang" class="value__donor">{{ donor.barang }}</p>
            <p v-else class="value__donor">Rp.{{ $formatPrice(donor.total) }}</p>
          </div>
        </div>
        <div class="time__donor-wrapper">
          <span class="time__donor">{{
            $moment(donor.transactionDate)
            .fromNow()
          }}</span>
        </div>
      </div>
      <div v-if="donor.note !== null || donor.note !== 'null'" class="card-body card__body-note">
        <span class="note">{{ donor.note }}</span>
      </div>
    </div>
    <div v-if="donors.length > 0 && this.currentPage < this.totalPage" class="loadmore__donatur-wrapper">
      <button type="button" class="btn btn-warning btn-sm btn__loadmore" @click="getDonor()">
        {{ loading ? 'Memuat ...' : 'Lainnya' }}
      </button>
    </div>
    <span v-if="donors.length === 0" class="text-muted">Donatur Tidak Ada</span>
  </div>
</template>

<script>
import LoaderListDonatur from '../../components/Loader/LoaderListDonatur.vue'
export default {
  name: 'ListDonatur',
  components: { LoaderListDonatur },
  props: {
    wakafId: { type: String, default: null }
  },
  data() {
    return {
      donors: [],
      currentPage: 0,
      totalPage: 0,
      perPage: 25,
      totalDonatur: 0,
      loading: true
    }
  },
  mounted() {
    this.getDonor()
  },
  methods: {
    getDonor() {
      const row = 1
      this.currentPage += row
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}campaigns/${this.wakafId}/donaturs?page=${this.currentPage}&limit=${this.perPage}&order=transaction_date`
        )
        .then(response => {
          const dataDonors = response.data.data
          this.totalDonatur = response.data.meta.total
          if (dataDonors.length > 0) {
            dataDonors.forEach(value => {
              const objDonor = {
                transactionId: value.transaction_id,
                transactionDate: value.transaction_date,
                displayName: value.display_as_name && value.display_as_name != "undefined" ? value.display_as_name : "Hamba Allah",
                firstName: value.user !== null && value.user.first_name !== null ? value.user.first_name : 'Hamba Allah',
                lastName: value.user !== null ? value.user.last_name : null,
                photoUrl: value.user !== null ? value.user.photo_url : null,
                total: value.total,
                barang: value.wakaf_barang,
                note: value.note && value.note != "undefined" ? value.note : null
              }
              this.donors.push(objDonor)
              this.totalPage = response.data.meta.last_page
              this.loading = false
            })
          } else {
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
